import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import AvBlogExerpt from '../components/AvBlogExerpt';
import { Link } from "gatsby"
import axios from 'axios';

class AvBlogExerpts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blogPosts: []
    };

    this.chunkArray = this.chunkArray.bind(this);
  }

  componentDidMount() {
    axios.get("https://public-api.wordpress.com/rest/v1.1/sites/artventuresframing.wordpress.com/posts/")
        .then(res => {
          if (this.props.frontPage) {
            var firstTwoPosts = [res.data.posts[0],
                                  res.data.posts[1]];
            this.setState({blogPosts: firstTwoPosts});
          } else {
            this.setState({blogPosts: res.data.posts});
          }
        })
        .catch(error => {
          console.log('caught an error!');
          console.log(error.toString());
        });

  }

  chunkArray(myArray, chunk_size) {
    var results = [];

    while (myArray.length) {
        results.push(myArray.splice(0, chunk_size));
    }

    return results;
  }

  render() {
    return (
      <div>
        <Row id="" className="d-flex align-items-center ">
          <Col className="text-center">
          <span id="mediaAnchor" className="anchorSpan"></span>
            <h1 className="">ArtVentures Blog</h1>
          </Col>
        </Row>
        <Row>
            {this.state.blogPosts.map(function(blogPost) {
              return(
                  <AvBlogExerpt key={blogPost.link} post={blogPost} />
              )
            })}
        </Row>
      </div>

    );
  }
}
export default AvBlogExerpts;
