import React from 'react';
import {Helmet} from "react-helmet";
import { Container, Row, Col, Button, Nav, Navbar, NavItem, NavLink } from 'reactstrap';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { Link } from "gatsby"
import axios from 'axios';
import museumIcon from "../images/museum.png"
import frameIcon from "../images/frame.png"

import { getLatestWPPost } from '../components/AvWordpressUtils'

import AvNav from '../components/AvNavbar'
import AvBlogEntry from '../components/AvBlogEntry'
import AvSplash from '../components/AvSplash'
import AvContent from '../components/AvContent'
import AvContactFooter from '../components/AvContactFooter'
import AvGramDisplay from '../components/AvGramDisplay'
import AvGalleryDisplay from '../components/AvGalleryDisplay'
import AvBlogExerpts from '../components/AvBlogExerpts'
import AvAbout from '../components/AvAbout'
import AvEmailSignUp from '../components/AvEmailSignUp'
import '../components/index.css';

library.add(fab, fas)


//end class

class HomePage extends React.Component {

	getAboutArtventures() {
		return (
			<p>ArtVentures is a custom framing shop in the heart of downtown Brighton, Michigan.
					Stop in to see why ArtVentures has been Brightons premier custom framing destination for over 25 years.
					<br/><br/>
					</p>
		)
	}
	getWhatWeDo() {
		return (
			<p>We specialize in custom framing for all of your personal and commercial needs.
					Artwork, paintings, posters, jerseys, as well as shadow boxes for your collectibles and memorabilia and so much more.
					Bring your project in for a custom framed design or call us to setup a visit to your home or office.</p>
		)
	}
	getAboutSpan() {
		return(<span id="aboutAnchor" className="anchorSpan"></span>);
	}

	navItemLinks = [
	  {location:'/#aboutAnchor',text:'About'},
		{location:'/#mediaAnchor',text:'Media'},
		{location:'https://shop.artventuresframing.com',text:'Shop'},
		{location:'/gallery',text:'Gallery'},
		{location:'#contactAnchor',text:'Contact'}
	];

	render() {
		return (
		<div id="homePage">
			<AvNav navItems={this.navItemLinks}/>
			<AvSplash />
			<Container id="" fluid>
				<Row id="aboutRow" className="contentRow d-flex align-items-center row-eq-height" >
					<AvAbout aboutId="aboutAV" header="About ArtVentures" iconImg={frameIcon} text={this.getAboutArtventures()} anchor={this.getAboutSpan()}/>
					<AvAbout aboutId="whatWeDo" header="What We Do" iconImg={museumIcon} text={this.getWhatWeDo()} />
				</Row>
				<Row className="contentRow">
					<Col>
						<AvBlogExerpts frontPage="true"/>
					</Col>
					<Col id="" sm="12" md="6" lg="6">
						<AvGalleryDisplay displayType="home" />
					</Col>
				</Row>
				<Row className="emailSignUp">
					<AvEmailSignUp/>
				</Row>
			</Container>
			<AvContactFooter />
			<Helmet>
				<script> var _ctct_m = "c190968ffa54e14f95656061d909892c"; </script>
				<script id="signupScript" src="//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js" async defer></script>
			</Helmet>
		</div>
		);
	}
}

// ========================================


export default HomePage
