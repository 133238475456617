import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import avInterior from "../images/avInterior.jpg"
import avCircleLogo from "../images/avCircleLogo.svg"
import visitShop from "../images/visitShop.png"


class AvSplash extends React.Component {
	render() {
		return (
			<Container id="splash" className="d-flex align-items-center anchorContainer" fluid>
				<span id="splashAnchor" className="anchorSpan"></span>
				<Row id="splashRow" className=" align-items-center ">
					<Col sm="12" md="6" lg={{ size: 5, offset: 1 }} className="text-center">
						<img id="splashLogo" src={avCircleLogo} className="img-fluid" />
					</Col>
					<Col xs="12" md="6" lg={{ size: 6 }} className="text-center">
						<a href="https://shop.artventuresframing.com" ><img src={visitShop} className="img-fluid" /></a>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default AvSplash;
