import React from 'react';

class AvContent extends React.Component {

	createAnchorId(id) {
		return id + 'Anchor';
	}

	render() {

		return (
			<div ref={this.props.innerRef} id={this.props.id} className="anchorContainer">
				<span id={this.createAnchorId(this.props.id)} className="anchorSpan"></span>
					{this.props.paragraph}
			</div>
		);
	}
}

export default AvContent;
