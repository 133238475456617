import React from 'react';
import { Container, Row, Col, Button, Link } from 'reactstrap';
import avMaraExample from "../images/avMaraExample.jpg"


class AvBlogEntry extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<Row className="blogEntryTitle d-flex align-items-center ">
					<Col className="blogEntryHeader text-center">
						<h3 className="">{this.props.title}</h3>
					</Col>
				</Row>
				<Row className="d-flex align-items-center justify-content-center">
					<Col sm="12" md="6" lg="8">
						<Row>
							<div dangerouslySetInnerHTML={{__html: this.props.content}}></div>
						</Row>
					</Col>
				</Row>
			</div>
		);
	}
}

export default AvBlogEntry;
