import React from 'react';
import { Container, Row, Col, Button, Link, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {cleanHtml} from '../components/AvBlogUtils';


class AvBlogExerpt extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
      modal: false
    };

		this.toggle = this.toggle.bind(this);
	}

	toggle() {
	 this.setState({
		 modal: !this.state.modal
	 });
 	}

	render() {
		return (
			<Col className="exerptCol">
				<Row className="d-flex align-items-center ">
					<Col className="text-center">
						<h3 className="">{this.props.post.title}</h3>
					</Col>
				</Row>
        <Row className="d-flex justify-content-center">
						<div dangerouslySetInnerHTML={{__html: cleanHtml(this.props.post.excerpt)}} />
				</Row>
        <Row id="" className="d-flex justify-content-center exFeaturedImg">
          <Col lg="9">
            <img src={this.props.post.featured_image} className="img-fluid" />
          </Col>
        </Row>
				<Row className="d-flex justify-content-center">
          <Button onClick={this.toggle} color="info">View the full post</Button>
					<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
	          <ModalHeader toggle={this.toggle}>{this.props.post.title}</ModalHeader>
	          <ModalBody>
	            <div dangerouslySetInnerHTML={{__html: cleanHtml(this.props.post.content)}} />
	          </ModalBody>
	          <ModalFooter>
	            <Button color="primary" onClick={this.toggle}>Close</Button>{' '}
	          </ModalFooter>
        	</Modal>
        </Row>

			</Col>
		);
	}
}

export default AvBlogExerpt;
