import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import axios from 'axios';

class AvGramDisplay extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      gramDisplayURLs: []
    };
  }

setGramDisplayPhotos = (posts) => {
  var URLs = {};
  for (var i = 0; i < posts.length; i++) {
    var postImgURL = posts[i].content;
    var regex = /<a.*?href="(.*?)"/;
    var src = regex.exec(postImgURL)[1];
    URLs[i] = src;
  }
  this.setState({ gramDisplayURLs: URLs});
};

componentDidMount() {
  axios.get("https://public-api.wordpress.com/rest/v1.1/sites/avphotos2018.wordpress.com/posts/")
      .then(res => {
        var wsData = res.data;
        this.setGramDisplayPhotos(wsData.posts);
      })
}

getGramImage(idx) {
  return(
    <Col lg="6" sm="6" md="6" xs="6" className="">
      <a href="http://www.instgram.com/artventuresframing/"><img id="" src={this.state.gramDisplayURLs[idx]} className="img-fluid" /></a>
    </Col>
  )
}

	render() {
		return (
      <div>
        <Row className="d-flex justify-content-center">
          <Col className="text-center">
            <h1>Instagram Happenings</h1>
          </Col>
        </Row>
				<Row id="gramTopRow" className="d-flex align-items-center">
					{this.getGramImage(0)}
          {this.getGramImage(1)}
				</Row>
        <Row className="d-flex align-items-center">
          {this.getGramImage(2)}
          {this.getGramImage(3)}
				</Row>
			</div>
		);
	}
}

export default AvGramDisplay;
