function cleanElement(element) {
  let div = document.createElement('div');
  div.innerHTML = element;
  let scripts = div.querySelectorAll('style, scripts, img');
  let i = scripts.length;
  while (i--) {
    if (scripts[i].tagName == 'IMG') {
      scripts[i].setAttribute("class", 'img-fluid');
      continue;
    }
    scripts[i].parentNode.removeChild(scripts[i]);
  }
  return div.innerHTML;
}

export function cleanHtml(htmlContent) {
  var cleanContent = cleanElement(htmlContent);
  return cleanContent;
};
