import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import avInterior from "../images/avInterior.jpg"
import avLogoGreen from "../images/avLogoGreen.svg"


class AvAbout extends React.Component {
  constructor(props) {
    super(props);
  }

	render() {
		return (
      <Col id={this.props.aboutId} sm="12" md="6" lg="6">
        {this.props.anchor}
        <Row>
          <Col className="text-center">
            <h1>{this.props.header}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs="3" sm="2" md="2" lg="2">
            <img src={this.props.iconImg} className="img-fluid" />
          </Col>
          <Col className="aboutTextCol">
            {this.props.text}
          </Col>
        </Row>
      </Col>
		);
	}
}

export default AvAbout;
