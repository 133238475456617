import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';


class AvEmailSignUp extends React.Component {
	render() {
		return (
			<Col className="text-center" id="emailCol" sm="12" md="6" lg={{ size: 6, offset: 3 }}>
        		<div class="ctct-inline-form" data-form-id="20603b56-12b4-49be-80bc-629e43ab2241"></div>
			</Col>
		);
	}
}

export default AvEmailSignUp;
